<template>
  <div class="loginPage_logo logo"></div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.logo {
    background: url(./resources/teplo_logo.png) no-repeat;
    width: 100%;
    background-position: center;
}
</style>
